import { useApiCall } from '@/hooks/useApiCall';
import { FunctionComponent, useEffect, useState } from 'react';
import { locationService } from '../../api/locations/location.service';
import { LocationAssetModel } from '../../types/LocationAssetModel';
import { AssetsAtLocationGrid } from './AssetsAtLocationGrid';
import { useParams, useSearchParams } from 'react-router-dom';
import { useFormatting } from '@/hooks/useFormatting';
import { DisplayMode } from '@/types/display-mode.enum';
import LocationHierarchyToggleButton from '../LocationHierarchyToggleButton';
import { PagePaginationResultDto, PageSortOption, PageSortOrder } from '@/lib/api/pagination.page.dto';
import PagedResultDataText from '@/components/filterbar/PagedResultDataText';
import PaginationControls from '@/components/grid/PaginationControls';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import { FilterValues } from '@/components/filterbar/FilterBarContext';

const ASSET_FETCH_LIMIT = 1000;

export const LocationAssetsTab: FunctionComponent = () => {
  const { formatNumber } = useFormatting();
  const [selectedRows, setSelectedRows] = useState<LocationAssetModel[]>([]);
  const { locationId } = useParams();
  if (!locationId) {
    return;
  }

  const locationIdNumber = Number.parseInt(locationId);

  const [searchParams, setSearchParams] = useSearchParams();
  const { setFiltersToUrl, filters } = useQueryParamsFilters(
    {
      pageNumber: searchParams.get('pageNumber') ? Number.parseInt(searchParams.get('pageNumber')!) : 1,
    },
    CacheKey.LOCATION_ASSET_FILTERS,
  );

  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.INDIVIDUAL);

  const {
    data: assetData,
    isLoading: assetIsLoading,
    isError: assetIsError,
    fetchData: fetchAssets,
    setApiCallArg,
  } = useApiCall<PagePaginationResultDto<LocationAssetModel>>(() =>
    locationService.getAssets(
      {
        locationId: filters.current.locationId ?? locationIdNumber,
      },
      {
        page: filters.current.pageNumber,
        limit: ASSET_FETCH_LIMIT,
        order: filters.current.sortDirection,
        sort: filters.current.sortOption,
      },
    ),
  );

  const onAssetsMoved = () => {
    fetchAssets();
  };

  useEffect(() => {
    if (displayMode === DisplayMode.HIERARCHICAL) {
      setApiCallArg(() =>
        locationService.getAssetsHierarchical(
          {
            locationId: filters.current.locationId ?? locationIdNumber,
          },
          {
            page: filters.current.pageNumber,
            limit: ASSET_FETCH_LIMIT,
            order: filters.current.sortDirection,
            sort: filters.current.sortOption,
          },
        ),
      );
    } else {
      setApiCallArg(() =>
        locationService.getAssets(
          {
            locationId: filters.current.locationId ?? locationIdNumber,
          },
          {
            page: filters.current.pageNumber,
            limit: ASSET_FETCH_LIMIT,
            order: filters.current.sortDirection,
            sort: filters.current.sortOption,
          },
        ),
      );
    }
  }, [displayMode]);

  function clearSelection() {
    setSelectedRows([]);
  }

  const handleSearch = (filterValues: FilterValues, page?: number) => {
    console.log('handleSearch', filterValues, page);
    setFiltersToUrl({
      ...filterValues,
      pageNumber: page ?? filters.current.pageNumber,
    });

    clearSelection();

    if (displayMode === DisplayMode.HIERARCHICAL) {
      setApiCallArg(() =>
        locationService.getAssetsHierarchical(
          {
            locationId: filters.current.locationId ?? locationIdNumber,
          },
          {
            page: filters.current.pageNumber,
            limit: ASSET_FETCH_LIMIT,
            order: filters.current.sortDirection,
            sort: filters.current.sortOption,
          },
        ),
      );
    } else {
      setApiCallArg(() =>
        locationService.getAssets(
          {
            locationId: filters.current.locationId ?? locationIdNumber,
          },
          {
            page: filters.current.pageNumber,
            limit: ASSET_FETCH_LIMIT,
            order: filters.current.sortDirection,
            sort: filters.current.sortOption,
          },
        ),
      );
    }
  };

  const onPageChanged = (event: React.ChangeEvent<unknown>, page: number) => {
    handleSearch(
      {
        ...filters.current,
        pageNumber: page,
      },
      page,
    );
  };

  const onSortChange = (sortOption?: PageSortOption, sortDirection?: PageSortOrder) => {
    handleSearch({
      ...filters.current,
      sortOption,
      sortDirection,
    });
  };

  return (
    <div className="flex h-full flex-1 flex-grow  max-w-[1920px] ">
      <div className="flex flex-col h-full w-full">
        <div className="text-md flex items-center justify-end  w-full font-bold">
          {/* {assetData && (
            <div className="">
              {formatNumber(assetData?.length)}
              {assetData?.length === 1000 && '+'} Asset{assetData?.length > 1 && 's'} found{' '}
              {assetData.length >= 1000 && `(Showing first ${formatNumber(assetData?.length)})`}
            </div>
          )} */}
          <div className="text-xs flex items-center scale-75 origin-right">
            <LocationHierarchyToggleButton displayMode={displayMode} onChange={setDisplayMode} reverseOrder />
          </div>
        </div>
        <AssetsAtLocationGrid
          data={assetData?.data}
          isError={assetIsError}
          isLoading={assetIsLoading}
          onAssetsMoved={onAssetsMoved}
          onSortChange={onSortChange}
          sortOption={filters.current.sortOption}
          sortDirection={filters.current.sortDirection}
          selectedRows={selectedRows}
          onSelectionChanged={setSelectedRows}
        ></AssetsAtLocationGrid>

        <div className="flex items-center gap-x-4">
          <PaginationControls
            isLoading={assetIsLoading}
            totalPageCount={assetData?.totalPages ?? 1}
            currentPage={filters.current.pageNumber ?? 1}
            totalElements={assetData?.totalElements ?? 0}
            onChange={onPageChanged}
          />
          <PagedResultDataText
            data={assetData}
            //  name={t('event', { count: assetData?.totalElements ?? 0 })}
            name="Assets"
          />
        </div>
      </div>
    </div>
  );
};
