import { ApiResponse } from '@/lib/api/api-response';
import { apiClient } from '@/lib/api/base-client';
import {
  AssetTotalAtLocationDTO,
  AssetTypeBalanceAtLocationDto,
  BalanceAtLocationDto,
  LocationAssetDTO,
  LocationByAssetCountDTO,
  LocationByAssetMonetaryValueDTO,
  LocationCreateDTO,
  LocationReadDTO,
  LocationUpdateDTO,
} from './location.contracts';
import { LocationModel } from '../../types/LocationModel';
import { AssetTotalAtLocationModel } from '../../types/AssetTotalAtLocationModel';
import { AssetTypeTotalModel } from '@/modules/asset-types';
import { LocationAssetModel } from '../../types/LocationAssetModel';
import { AssetTypeBalanceAtLocationModel } from '../../types/AssetTypeBalanceAtLocationModel';
import { PagePaginationQueryDtoParams, PagePaginationResultDto } from '@/lib/api/pagination.page.dto';

export class LocationService {
  public basePath = 'location';

  async create(locationToCreate: LocationCreateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.post<undefined>(this.basePath + '/', locationToCreate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async update(locationId: number, locationToUpdate: LocationUpdateDTO): Promise<ApiResponse<undefined>> {
    try {
      const applicationResponse = await apiClient.patch<undefined>(`${this.basePath}/${locationId}`, locationToUpdate);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async delete(locationId: number): Promise<ApiResponse<void>> {
    try {
      const applicationResponse = await apiClient.delete<void>(`${this.basePath}/${locationId}`);
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAll({ query }: { query: string }): Promise<ApiResponse<LocationModel[]>> {
    try {
      const applicationResponse = await apiClient.get<LocationReadDTO[]>(this.basePath, { query });
      return applicationResponse.processPayload((payload) => {
        return payload.map(LocationModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAllHierarchical({ query }: { query: string }): Promise<ApiResponse<LocationModel[]>> {
    try {
      const applicationResponse = await apiClient.get<LocationReadDTO[]>(`${this.basePath}/hierarchical`, { query });
      return applicationResponse.processPayload((payload) => {
        return payload.map(LocationModel.fromDTO);
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getById(id: number): Promise<ApiResponse<LocationModel>> {
    try {
      const applicationResponse = await apiClient.get<LocationReadDTO>(`${this.basePath}/${id}`);
      return applicationResponse.processPayload(LocationModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAssetTotals(
    {
      includeEmptyLocations,
      assetTypeIds,
      includeInTransitAssets,
      labelValueIds,
    }: { includeEmptyLocations: boolean; includeInTransitAssets: boolean; assetTypeIds?: number[]; labelValueIds?: number[] } = {
      includeEmptyLocations: false,
      includeInTransitAssets: false,
    },
  ): Promise<ApiResponse<AssetTypeBalanceAtLocationModel[]>> {
    try {
      const applicationResponse = await apiClient.get<AssetTypeBalanceAtLocationDto[]>(`${this.basePath}/assets/totals`, {
        includeEmptyLocations,
        assetTypeIds,
        includeInTransitAssets,
        labelValueIds,
      });
      return applicationResponse.processPayload((payload) => payload.map(AssetTypeBalanceAtLocationModel.fromDTO));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAssetTotalAtLocation({
    locationId,
    labelValueIds,
    assetTypeIds,
  }: { locationId?: number; labelValueIds?: number[]; assetTypeIds?: number[] } = {}): Promise<ApiResponse<AssetTotalAtLocationModel>> {
    try {
      const applicationResponse = await apiClient.get<AssetTotalAtLocationDTO>(`${this.basePath}/assets/total`, {
        locationId,
        labelValueIds,
        assetTypeIds,
      });
      return applicationResponse.processPayload(AssetTotalAtLocationModel.fromDTO);
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getByQuery({ query }: { query: string }): Promise<ApiResponse<LocationModel[]>> {
    try {
      const res = await apiClient.get<LocationReadDTO[]>(`${this.basePath}/search`, {
        query,
      });
      return res.processPayload((p) => p.map(LocationModel.fromDTO));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getEligibleParentsForLocationId({
    query,
    locationId,
  }: {
    query: string;
    locationId?: number;
  }): Promise<ApiResponse<LocationModel[]>> {
    try {
      const res = await apiClient.get<LocationReadDTO[]>(`${this.basePath}/${locationId}/eligible-parents`, {
        query,
      });
      return res.processPayload((p) => p.map(LocationModel.fromDTO));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getBalance({ locationId }: { locationId: number }): Promise<ApiResponse<AssetTypeTotalModel[]>> {
    try {
      const applicationResponse = await apiClient.get<BalanceAtLocationDto[]>(`${this.basePath}/${locationId}/balance`);
      return applicationResponse.processPayload((p) => p.map(AssetTypeTotalModel.fromDTO));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAssets(
    { locationId }: { locationId: number },
    pagination: PagePaginationQueryDtoParams,
  ): Promise<ApiResponse<PagePaginationResultDto<LocationAssetModel>>> {
    try {
      const applicationResponse = await apiClient.get<PagePaginationResultDto<LocationAssetDTO>>(`${this.basePath}/${locationId}/assets`, {
        ...pagination,
      });
      return applicationResponse.processPagedPayload((p) => p.map(LocationAssetModel.fromDTO));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getAssetsHierarchical(
    { locationId }: { locationId: number },
    pagination: PagePaginationQueryDtoParams,
  ): Promise<ApiResponse<PagePaginationResultDto<LocationAssetModel>>> {
    try {
      const applicationResponse = await apiClient.get<PagePaginationResultDto<LocationAssetDTO>>(
        `${this.basePath}/${locationId}/assets/hierarchical`,
        { ...pagination },
      );
      return applicationResponse.processPagedPayload((p) => p.map(LocationAssetModel.fromDTO));
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getTopLocationsByAssetCount({ assetTypeId, labelValueIds }: { assetTypeId?: number; labelValueIds?: number[] } = {}): Promise<
    ApiResponse<LocationByAssetCountDTO[]>
  > {
    try {
      const applicationResponse = await apiClient.get<LocationByAssetCountDTO[]>(`${this.basePath}/top/asset-count`, {
        assetTypeId,
        labelValueIds,
      });
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getTopLocationsByAssetMonetaryValue({ assetTypeId, labelValueIds }: { assetTypeId?: number; labelValueIds?: number[] } = {}): Promise<
    ApiResponse<LocationByAssetMonetaryValueDTO[]>
  > {
    try {
      const applicationResponse = await apiClient.get<LocationByAssetMonetaryValueDTO[]>(`${this.basePath}/top/asset-monetary-value`, {
        assetTypeId,
        labelValueIds,
      });
      return applicationResponse;
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }

  async getCount(): Promise<ApiResponse<number>> {
    try {
      const applicationResponse = await apiClient.get<{ count: number }>(`${this.basePath}/count`);
      return applicationResponse.processPayload((payload) => {
        return payload.count;
      });
    } catch (error) {
      return ApiResponse.UnknownErrorResponse();
    }
  }
}

export const locationService = new LocationService();
