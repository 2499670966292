import { TrackerReadDTO, TrackerType } from '../api/trackers/tracker.contracts';

// TrackerArguments Interface
export interface TrackerArguments {
  id: number;
  type: TrackerType;
  epc?: string | undefined | null;
  barcode?: string | undefined | null;
  deviceId?: string | undefined | null;
  asset?: {
    id: number;
    code: string;
  };
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

// TrackerFieldNames enum
export enum TrackerModelFieldNames {
  Id = 'id',
  Name = 'name',
  Type = 'type',
  Code = 'code',
  Epc = 'epc',
  Barcode = 'barcode',
  DeviceId = 'deviceId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  DeletedAt = 'deletedAt',
}

// Tracker Class
export class TrackerModel {
  id: number;
  type: TrackerType;
  epc?: string | undefined | null;
  barcode?: string | undefined | null;
  deviceId?: string | undefined | null;
  asset?: {
    id: number;
    code: string;
  };
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;

  constructor(args: TrackerReadDTO) {
    this.id = args.id;
    this.type = args.type;
    this.epc = args.epc;
    this.barcode = args.barcode;
    this.deviceId = args.deviceId;
    this.asset = args.asset;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;
    this.deletedAt = args.deletedAt;
  }

  get uniqueIdentifier(): string {
    switch (this.type) {
      case TrackerType.Barcode:
        return this.barcode ? this.barcode : 'Unknown Tracker';

      case TrackerType.RFID:
        return this.epc ? this.epc : 'Unknown Tracker';

      case TrackerType.UnaTag:
      case TrackerType.BLE:
        return this.deviceId ? this.deviceId : 'Unknown Tracker';

      default:
        return 'Unknown Tracker';
    }
  }

  toDTO(): TrackerReadDTO {
    return {
      id: this.id,
      type: this.type,
      epc: this.epc,
      barcode: this.barcode,
      deviceId: this.deviceId,
      asset: this.asset,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      deletedAt: this.deletedAt ? this.deletedAt : undefined,
    };
  }

  static fromDTO(dto: TrackerReadDTO): TrackerModel {
    return new TrackerModel({
      id: dto.id,
      type: dto.type,
      epc: dto.epc,
      barcode: dto.barcode,
      deviceId: dto.deviceId,
      asset: dto.asset,
      createdAt: dto.createdAt,
      updatedAt: dto.updatedAt,
      deletedAt: dto.deletedAt,
    });
  }
}
