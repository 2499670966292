import { MenuItemGroup } from '@/modules/application';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';

export const DEVICE_CONFIGURATION_SUB_MENU_ITEMS: MenuItemGroup[] = [
  {
    label: 'Devices',
    children: [
      {
        activeRouteIds: ['/app/configuration/devices*'],
        label: 'Devices',
        url: '/app/configuration/devices/all',
        requiredPermission: Permission.DEVICES_VIEW,
      },
    ],
  },
];
