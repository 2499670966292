export enum UserSettingType {
  'STRING' = 'STRING',
  'NUMBER' = 'NUMBER',
  'BOOLEAN' = 'BOOLEAN',
  'JSON' = 'JSON',
}

// String can be either JSON or an actual String value
export type UserSettingValue = number | string | boolean | object;

export enum UserSettingKey {
  'DARK_MODE_ENABLED' = 'DARK_MODE_ENABLED',
  'BALANCE_OVERVIEW_COLUMN_STATE' = 'BALANCE_OVERVIEW_COLUMN_STATE',
  'BALANCE_OVERVIEW_HIERARCHICAL_COLUMN_STATE' = 'BALANCE_OVERVIEW_HIERARCHICAL_COLUMN_STATE',
  'EVENTS_GRID_COLUMN_STATE' = 'EVENTS_GRID_COLUMN_STATE',
  'BALANCE_OVERVIEW_DISPLAY_MODE' = 'BALANCE_OVERVIEW_DISPLAY_MODE',
  'LOCATION_OVERVIEW_DISPLAY_MODE' = 'LOCATION_OVERVIEW_DISPLAY_MODE',
  'LOCATION_INDIVIDUAL_GRID_COLUMN_STATE' = 'LOCATION_INDIVIDUAL_GRID_COLUMN_STATE',
  'LOCATION_HIERARCHICAL_GRID_COLUMN_STATE' = 'LOCATION_HIERARCHICAL_GRID_COLUMN_STATE',
  'ASSET_OVERVIEW_GRID_COLUMN_STATE' = 'ASSET_OVERVIEW_GRID_COLUMN_STATE',
  'ASSET_MOVEMENT_TAB_GRID_COLUMN_STATE' = 'ASSET_MOVEMENT_TAB_GRID_COLUMN_STATE',
  'LOCATION_MOVEMENT_TAB_GRID_COLUMN_STATE' = 'LOCATION_MOVEMENT_TAB_GRID_COLUMN_STATE',
  'TRACKER_OVERVIEW_GRID_COLUMN_STATE' = 'TRACKER_OVERVIEW_GRID_COLUMN_STATE',
}

export interface UserSettingReadDTO {
  id: number;
  key: UserSettingKey;
  type: UserSettingType;
  value: UserSettingValue;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface UserSettingUpdateDTO {
  key: UserSettingKey;
  type: UserSettingType;
  value: UserSettingValue;
}
