import { FunctionComponent, useMemo, useCallback, useRef, useEffect } from 'react';
import {
  ColDef,
  GridOptions,
  RowDoubleClickedEvent,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  SelectionChangedEvent,
  GetDetailRowDataParams,
  SortChangedEvent,
  GetContextMenuItemsParams,
} from 'ag-grid-community';
import { PoGrid, BASE_GRID_OPTIONS } from '@/components/grid/PoGrid';
import { LocationModel } from '@/modules/locations/types/LocationModel';
import LabelCellRenderer, { LabelCellRendererParams } from '@/components/grid/cells/LabelCellRenderer';
import { useGridColumnState } from '@/hooks/useGridColumnState';
import { UserSettingKey } from '@/modules/users/api/user-settings/user-setting.contracts';
import { ColumnID } from '@/components/grid/column-ids';
import { PageSortOption, PageSortOrder } from '@/lib/api/pagination.page.dto';
import { LocationSortOption } from '../api/locations/location.contracts';
import { useExportToExcel } from '@/hooks/useExportToExcel';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash-es';

interface HierarchicalLocationsGridProps {
  data: LocationModel[] | undefined;
  isLoading: boolean;
  onSelectionChanged: (selectedRows: LocationModel[]) => void;
  onRowDoubleClicked: (locationId: string) => void;
  hasPermission: boolean;
  initialSortOption: PageSortOption;
  initialSortOrder: PageSortOrder;
  onSortChanged: (sortOrder: SortChangedEvent<LocationModel>) => void;
}

const HierarchicalLocationsGrid: FunctionComponent<HierarchicalLocationsGridProps> = ({
  data,
  isLoading,
  onSelectionChanged,
  onRowDoubleClicked,
  hasPermission,
  initialSortOption,
  initialSortOrder,
  onSortChanged,
}) => {
  const { exportToExcel } = useExportToExcel();
  const gridApi = useRef<GridApi<LocationModel> | null>(null);

  // Ref to hold the latest data value
  const dataRef = useRef<LocationModel[] | undefined>(data);

  // Update the ref whenever `data` changes
  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  // Initialize the grid column state hook
  const {
    setColumnStateGridApi,
    handleColumnStateChange,
    columnState,
    applyStateToDefinitions,
    setIsAutoSaveEnabled,
    setDefaultColumnState,
  } = useGridColumnState(UserSettingKey.LOCATION_HIERARCHICAL_GRID_COLUMN_STATE);

  const columnDefs = useMemo<ColDef<LocationModel>[]>(() => {
    const checkboxColumn: ColDef<LocationModel> = {
      colId: ColumnID.SELECTION_CHECKBOX,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      resizable: false,
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      suppressColumnsToolPanel: true,
      suppressMenu: true,
      lockVisible: true,
      sortable: false,
    };

    const columns: ColDef<LocationModel>[] = [
      {
        colId: ColumnID.LOCATION_NAME,
        cellRenderer: 'agGroupCellRenderer',
        field: 'dto.name',
        headerName: 'Name',
      },
      {
        colId: ColumnID.LOCATION_CODE,
        field: 'dto.code',
        headerName: 'Code',
        width: 150,
      },
      {
        colId: ColumnID.LOCATION_SUBLOCATIONS,
        field: 'totalChildrenCount',
        headerName: 'Sublocations',
        valueFormatter: (params) => {
          return params.data?.totalChildrenCount && params.data.totalChildrenCount > 0 ? params.data?.totalChildrenCount.toString() : '-';
        },
      },
      {
        colId: ColumnID.LOCATION_LABELS,
        field: 'dto.labels',
        headerName: 'Labels',
        cellRenderer: LabelCellRenderer,
        cellRendererParams: (params: ICellRendererParams<LocationModel>): LabelCellRendererParams => {
          const labels = params.data?.dto.labels ?? [];
          return {
            labels,
          };
        },
        valueFormatter: (params) => {
          return params.data?.dto.labels?.map((label) => label.value.value).join(', ') ?? '-';
        },
        flex: 1,
      },
    ];

    if (hasPermission) {
      columns.unshift(checkboxColumn);
    }

    // Set default column state if not already set
    if (!columnState) {
      setDefaultColumnState([
        { colId: ColumnID.SELECTION_CHECKBOX, hide: !hasPermission },
        { colId: ColumnID.LOCATION_NAME, hide: false },
        { colId: ColumnID.LOCATION_CODE, hide: false },
        { colId: ColumnID.LOCATION_SUBLOCATIONS, hide: false },
        { colId: ColumnID.LOCATION_LABELS, hide: false },
      ]);
    }

    // Apply saved column state to the column definitions
    applyStateToDefinitions(columns);

    return columns;
  }, [hasPermission, columnState]);

  // Enable auto-saving of column state
  useEffect(() => {
    setIsAutoSaveEnabled(true);
  }, []);

  // Clean up gridApi when component is unmounted
  useEffect(() => {
    return () => {
      gridApi.current = null;
    };
  }, []);

  const collectSelectedRows = useCallback(() => {
    const allSelectedRows: LocationModel[] = [];

    if (gridApi.current && !gridApi.current.isDestroyed()) {
      // Collect selected rows from the master grid
      gridApi.current.forEachNode((node) => {
        if (node.isSelected()) {
          allSelectedRows.push(node.data!);
        }
      });

      // Collect selected rows from all detail grids recursively
      const collectFromDetailGrids = (api: GridApi<LocationModel>) => {
        api.forEachDetailGridInfo((detailGridInfo) => {
          const detailApi = detailGridInfo.api as GridApi<LocationModel>;

          detailApi.forEachNode((node) => {
            if (node.isSelected()) {
              allSelectedRows.push(node.data!);
            }
          });

          // Recursively collect from nested detail grids
          collectFromDetailGrids(detailApi);
        });
      };

      collectFromDetailGrids(gridApi.current);
    }

    onSelectionChanged(allSelectedRows);
  }, [onSelectionChanged]);

  const onExportToExcelClicked = () => {
    if (!isNil(dataRef.current) && !isEmpty(dataRef.current)) {
      const exportData: Record<string, string | number | undefined>[] = [];

      // Helper function to process each location and recursively its children
      const processLocation = (location: LocationModel, parentLevel1?: string, parentLevel2?: string) => {
        let row: Record<string, string | number | undefined> = {};

        // Correctly fill the hierarchy levels
        if (!parentLevel1 && !parentLevel2) {
          // Top-level location
          row = {
            'Location Name Level 1': location.dto.name, // Fill Level 1 for top-level locations
            'Location Name Level 2': '', // No parent for top-level
            'Location Name Level 3': '', // No grandparent for top-level
          };
        } else if (parentLevel1 && !parentLevel2) {
          // Second-level location
          row = {
            'Location Name Level 1': parentLevel1, // Parent goes to Level 1
            'Location Name Level 2': location.dto.name, // Current location goes to Level 2
            'Location Name Level 3': '', // No grandparent
          };
        } else if (parentLevel1 && parentLevel2) {
          // Third-level location
          row = {
            'Location Name Level 1': parentLevel2, // Grandparent goes to Level 1
            'Location Name Level 2': parentLevel1, // Parent goes to Level 2
            'Location Name Level 3': location.dto.name, // Current location goes to Level 3
          };
        }

        // Add other relevant fields to the row
        row = {
          ...row,
          Code: location.dto.code,
          Name: location.dto.name,
          Sublocations: location.totalChildrenCount,
          ...location.dto.labels?.reduce<Record<string, string>>((acc, label, index) => {
            acc[`Label_${index + 1}`] = label.value.value;
            return acc;
          }, {}),
        };

        // Push the processed row into the export data array
        exportData.push(row);

        // Recursively process each child, passing the current location as the parent and the current parent as the grandparent
        location.children?.forEach((child) => {
          processLocation(child, location.dto.name, parentLevel1); // Current location becomes parent, parentLevel1 becomes grandparent
        });
      };

      // Process all top-level locations
      dataRef.current.forEach((location) => {
        processLocation(location);
      });

      // Export to Excel
      exportToExcel(exportData, `locations_${dayjs().format('YYYY_MM_DD_HHmmss')}.xlsx`);
    }
  };

  const gridOptions: GridOptions<LocationModel> = useMemo(
    () => ({
      ...BASE_GRID_OPTIONS,
      getContextMenuItems: (params) => [
        'copy',
        'separator',
        {
          name: 'Export to Excel',
          action: () => onExportToExcelClicked(),
        },
      ],
      onRowDoubleClicked(event: RowDoubleClickedEvent<LocationModel>) {
        const id = event.data?.dto.id;
        onRowDoubleClicked(id ? id.toString() : '');
      },
      masterDetail: true,
      detailRowHeight: 240,
      detailRowAutoHeight: true,
      isRowMaster: (loc) => {
        if (loc.children) {
          return loc.children.length > 0;
        }
        return false;
      },
      detailCellRendererParams: {
        detailGridOptions: {
          getContextMenuItems: (params) => [
            'copy',
            'separator',
            {
              name: 'Export to Excel',
              action: () => onExportToExcelClicked(),
            },
          ],
          columnDefs: columnDefs,
          defaultColDef: {
            flex: 1,
          },
          masterDetail: true,
          detailRowHeight: 240,
          detailRowAutoHeight: true,
          isRowMaster: (loc) => {
            if (loc.children) {
              return loc.children.length > 0;
            }
            return false;
          },
          detailCellRendererParams: {
            detailGridOptions: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              getContextMenuItems: (params: GetContextMenuItemsParams<LocationModel, any>) => [
                'copy',
                'separator',
                {
                  name: 'Export to Excel',
                  action: () => onExportToExcelClicked(),
                },
              ],
              columnDefs: columnDefs,
              defaultColDef: {
                flex: 1,
              },
              onRowDoubleClicked(event: RowDoubleClickedEvent<LocationModel>) {
                const id = event.data?.dto.id;
                onRowDoubleClicked(id ? id.toString() : '');
              },
              rowSelection: 'multiple',
              onSelectionChanged: (_: SelectionChangedEvent<LocationModel>) => {
                collectSelectedRows();
              },
            },
            getDetailRowData: (params: GetDetailRowDataParams) => {
              params.successCallback(params.data.children ?? []);
            },
            onSelectionChanged: (_: SelectionChangedEvent<LocationModel>) => {
              collectSelectedRows();
            },
          } as GridOptions<LocationModel>,
          onRowDoubleClicked(event: RowDoubleClickedEvent<LocationModel>) {
            const id = event.data?.dto.id;
            onRowDoubleClicked(id ? id.toString() : '');
          },
          rowSelection: 'multiple',
          onSelectionChanged: (_: SelectionChangedEvent<LocationModel>) => {
            collectSelectedRows();
          },
        } as GridOptions<LocationModel>,
        getDetailRowData: (params: GetDetailRowDataParams) => {
          params.successCallback(params.data.children ?? []);
        },
        onSelectionChanged: (_: SelectionChangedEvent<LocationModel>) => {
          collectSelectedRows();
        },
      } as GridOptions<LocationModel>,
      getRowId: (params) => params.data.dto.id.toString(),
      suppressRowClickSelection: true,
      rowSelection: 'multiple',
      onSelectionChanged: (_: SelectionChangedEvent<LocationModel>) => {
        collectSelectedRows();
      },
      onGridReady: (params: GridReadyEvent<LocationModel>) => {
        gridApi.current = params.api;
        if (initialSortOption && initialSortOrder) {
          // Convert the initial sort option to column ID
          let columnId: ColumnID | undefined;

          switch (initialSortOption) {
            case LocationSortOption.NAME:
              columnId = ColumnID.LOCATION_NAME;
              break;
            case LocationSortOption.CODE:
              columnId = ColumnID.LOCATION_CODE;
              break;
            case LocationSortOption.SUBLOCATIONS:
              columnId = ColumnID.LOCATION_SUBLOCATIONS;
              break;
            default:
              break;
          }
          if (!columnId) {
            return;
          }

          params.api.applyColumnState({
            state: [
              {
                colId: columnId,
                sort: initialSortOrder === PageSortOrder.ASC ? 'asc' : 'desc',
              },
            ],
            defaultState: { sort: null },
          });
        }
        setColumnStateGridApi(params.api);
      },
      // Grid column state change handlers
      onColumnMoved: handleColumnStateChange,
      onColumnVisible: handleColumnStateChange,
      onColumnResized: handleColumnStateChange,
      onColumnPinned: handleColumnStateChange,
      onSortChanged: (event) => {
        if (event.columns && event.columns[0] && event.source === 'uiColumnSorted') {
          event.api.applyColumnState({
            state: [
              {
                colId: event.columns[0].getColId(),
                sort: event.columns[0].getSort(),
              },
            ],
            defaultState: { sort: null },
          });
        }
        handleColumnStateChange(event);

        // Update sort options
        onSortChanged(event);
      },
    }),
    [collectSelectedRows, columnDefs, onRowDoubleClicked],
  );

  return (
    <PoGrid
      isLoading={isLoading}
      colDefs={columnDefs}
      rowData={data}
      gridOptions={gridOptions}
      disableDefaultGridOptions
      disableResizeColumnsToFit
    />
  );
};

export default HierarchicalLocationsGrid;
