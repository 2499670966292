import { useState, useEffect } from 'react';
import { app } from '../lib/firebase';
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  User,
  browserLocalPersistence,
  AuthErrorCodes,
} from 'firebase/auth';
import { Environment } from '@/config/Environment';
import { apiClient } from '@/lib/api/base-client';
import { SUPER_USERS_EMAIL_LIST } from '../configuration/configuration';
import { isNil } from 'lodash-es';
import { FirebaseError } from 'firebase/app';
import { isPrivatePath } from '@/router/router';
import { Routes } from '@/router/router-paths';

export function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [authError, setAuthError] = useState<string | null>(null);
  const [tenantId, setTenantId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);

  const auth = getAuth(app);

  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });

  const authStateChanged = async (authState: User | null) => {
    if (!authState) {
      return;
    }

    setLoading(true);
    setAuthUser(authState);
    setLoading(false);
  };

  async function signIn(email: string, password: string): Promise<User> {
    try {
      setAuthError(null);
      auth.setPersistence(browserLocalPersistence);
      const authUser = await signInWithEmailAndPassword(auth, email, password);
      console.log({ authUser });

      await setApiUrl(authUser.user);
      setAuthUser(authUser.user);
      return authUser.user;
    } catch (error) {
      console.log({ LOGIN_ERROR: error, IsFirebaseError: error instanceof FirebaseError });

      if (error instanceof FirebaseError) {
        switch (error.code) {
          case AuthErrorCodes.USER_DELETED:
            setAuthError('User not found');
            break;
          case AuthErrorCodes.INVALID_IDP_RESPONSE:
            setAuthError('Invalid credentials');
            break;
          case AuthErrorCodes.INVALID_PASSWORD:
            setAuthError('Invalid password');
            break;

          default:
            setAuthError('An unknown error occured');
            break;
        }
      } else {
        setAuthError('An unknown error occured');
      }
      throw Error('Login Failed');
    }
  }
  function signUp(email: string, password: string) {
    createUserWithEmailAndPassword(auth, email, password);
  }

  async function logOut() {
    try {
      await signOut(auth);

      setAuthUser(null);
      setTenantId(null);

      setLoading(false);

      if (isPrivatePath(window.location.pathname) || window.location.pathname === `${Routes.LOGIN}?logOut=true`) {
        // Use Window.location.href instead of navigate to clear all the state
        window.location.href = Routes.LOGIN;
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);
    return unsubscribe;
  }, []);

  const signInWithGoogle = () =>
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential) {
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          // redux action? --> dispatch({ type: SET_USER, user });
          // router.push("/app");
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });

  async function autoLogin(): Promise<User | null> {
    await auth.authStateReady();

    if (!auth.currentUser) {
      return null;
    }

    setAuthUser(auth.currentUser);
    await setApiUrl(auth.currentUser);
    return auth.currentUser;
  }

  async function setApiUrl(user: User) {
    const decodedToken = await user.getIdTokenResult();
    let tenantId = decodedToken.claims.tenantId as string;
    if (!isNil(user.email) && SUPER_USERS_EMAIL_LIST.some((email) => email === user.email)) {
      tenantId = 'resultcode';
    }

    setTenantId(tenantId);
    console.log({
      Environment: Environment.ENVIRONMENT,

      tenantId,
      DEV_API_URL: Environment.DEV_API_URL,
      isDevelopment: Environment.isDevelopment(),
    });

    if (Environment.DEV_API_URL && Environment.isDevelopment()) {
      apiClient.setBaseUrlOverride(Environment.DEV_API_URL);
    } else {
      apiClient.setBaseUrl(Environment.POSITO_ENVIRONMENT, tenantId);
    }
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  return {
    authUser,
    loading,
    signIn,
    signUp,
    logOut,
    signInWithGoogle,
    autoLogin,
    authError,
    tenantId,
  };
}
