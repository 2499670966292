import { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { AssetTypeCreateFormSchema, AssetTypeFormModel, AssetTypeFormSchema } from './AssetTypeFormModel';
import { zodResolver } from '@hookform/resolvers/zod';
import AssetTypeFormDisplay from './AssetTypeFormDisplay';
import { useConfirmationModal } from '@/components/modals/confirmation-modal/useConfirmationModal';
import { useNavigate } from 'react-router-dom';
import { assetTypeService } from '../../api/asset-types/asset-type.service';
import { useTranslation } from '@/lib';

interface AssetTypeFormLogicProps {
  initialValues: AssetTypeFormModel;
  onSubmit: (data: AssetTypeFormModel) => Promise<boolean>;
  isCreate?: boolean;
}

const AssetTypeFormLogic: FunctionComponent<AssetTypeFormLogicProps> = ({ initialValues, onSubmit, isCreate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const form = useForm<AssetTypeFormModel>({
    mode: 'onSubmit',
    defaultValues: initialValues,
    resolver: zodResolver(isCreate ? AssetTypeCreateFormSchema : AssetTypeFormSchema),
  });

  const { ModalComponent, handleOpenModal, setIsLoading, setIsModalOpen } = useConfirmationModal({
    onConfirm: onUnarchiveConfirmed,
    questionTitle: t('unarchive_asset_type_title'),
    questionText: t('unarchive_asset_type_text'),
    confirmButtonText: t('unarchive'),
    cancelButtonText: t('cancel'),
    closeOnConfirm: true,
  });

  async function onUnarchiveConfirmed(id?: number) {
    if (id) {
      setIsLoading(true);
      const response = await assetTypeService.unarchive(id);
      if (response.isSuccess) {
        navigate(`/app/configuration/assets/types/${id}`);
        setIsModalOpen(false);
      } else {
        setIsLoading(false);
      }
    }
  }

  const handleSubmit = async (data: AssetTypeFormModel) => {
    if (isCreate || data.code != initialValues.code) {
      const existingAssetTypeResponse = await assetTypeService.getByCode(data.code);

      if (existingAssetTypeResponse.isSuccess && existingAssetTypeResponse.payload) {
        if (existingAssetTypeResponse.payload.deletedAt) {
          handleOpenModal(existingAssetTypeResponse.payload.id);
          return;
        } else {
          form.setError('code', {
            type: 'manual',
            message: 'An asset type with this code already exists',
          });
          return;
        }
      }
    }

    await onSubmit(data)
      .then(() => form.reset(data))
      .catch((err) => console.error(err));
  };

  return (
    <>
      <AssetTypeFormDisplay form={form} onSubmit={handleSubmit} isCreate={isCreate} />
      {ModalComponent}
    </>
  );
};

export default AssetTypeFormLogic;
