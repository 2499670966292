import React, { FunctionComponent, useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { AssetTypeModel } from '../types/AssetTypeModel';
import { assetTypeService } from '../api/asset-types/asset-type.service';
import { useTranslation } from '@/lib';

interface AssetTypesSelectProps {
  selectedAssetTypeIds: number[];
  onChange: (selectedAssetTypes: AssetTypeModel[]) => void;
}

const AssetTypesSelect: FunctionComponent<AssetTypesSelectProps> = ({ selectedAssetTypeIds, onChange }) => {
  const [assetTypes, setAssetTypes] = useState<AssetTypeModel[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchAssetTypes = async () => {
      const response = await assetTypeService.getAll();
      if (response.isSuccess) {
        setAssetTypes(response.payload);
      }
    };
    fetchAssetTypes();
  }, []);

  const handleSelectionChange = (event: React.SyntheticEvent, value: AssetTypeModel[]) => {
    onChange(value);
  };

  return (
    <Autocomplete
      multiple
      disableClearable
      options={assetTypes}
      value={assetTypes.filter((option) => selectedAssetTypeIds.includes(option.id))}
      onChange={handleSelectionChange}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t('asset_types')}
          helperText={t('asset_types_helper_text')}
          InputLabelProps={{ shrink: true }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
    />
  );
};

export default AssetTypesSelect;
