import FilterBar from '@/components/filterbar/FilterBar';
import { FilterFieldName, FilterValues } from '@/components/filterbar/FilterBarContext';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import AssetTypesFilter from '@/components/filterbar/filters/AssetTypesFilter';
import LabelValuesFilter from '@/components/filterbar/filters/LabelValuesFilter';
import { useApiCall } from '@/hooks/useApiCall';
import { useTranslation } from '@/lib';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import TabbedLayoutTopBarHeader from '@/modules/application/components/TabbedLayoutTopBarHeader';
import { TabbedLayoutTopBarVerticalSeperator } from '@/modules/application/components/TabbedLayoutTopBarVerticalSeperator';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { locationService } from '@/modules/locations/api/locations/location.service';
import ClusterMap, { MarkerProps } from '@/modules/map/components/ClusterMap';
import { Refresh } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';

export const AssetsMapPage: FunctionComponent = () => {
  const { t } = useTranslation();

  // Use the query params filters hook to sync filters with URL and cache
  const { filters, setFiltersToUrl } = useQueryParamsFilters({}, CacheKey.ASSETS_MAP_FILTERS);

  const { data, fetchData, setApiCallArg } = useApiCall(() =>
    locationService.getAssetTotals({
      includeInTransitAssets: true,
      includeEmptyLocations: true,
      assetTypeIds: filters.current.assetTypeIds,
      labelValueIds: filters.current.labelValueIds,
    }),
  );

  const [locationMarkers, setLocationMarkers] = useState<MarkerProps[]>([]);

  useEffect(() => {
    if (data) {
      const markers = data
        .filter((d) => d.hasValidCoordinates)
        .map<MarkerProps>((d) => ({
          lat: d.coordinates ? d.coordinates[1] : 0,
          lng: d.coordinates ? d.coordinates[0] : 0,
          id: d.id,
          title: d.name,
          count: d.totalBalance,
          inTransitAssetId: d.inTransitAssetId,
        }));
      setLocationMarkers(markers);
    }
  }, [data]);

  const onSearch = (filterValues: FilterValues) => {
    // Set the filters to the URL and cache
    setFiltersToUrl(filterValues);

    // Update API call arguments based on the new filters
    setApiCallArg(() =>
      locationService.getAssetTotals({
        includeInTransitAssets: true,
        includeEmptyLocations: true,
        assetTypeIds: filterValues[FilterFieldName.assetTypeIds] as number[],
        labelValueIds: filterValues[FilterFieldName.labelValueIds] as number[],
      }),
    );
  };

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          actions={
            <Button variant="outlined" startIcon={<Refresh />} onClick={fetchData}>
              {t('refresh')}
            </Button>
          }
        >
          <TabbedLayoutTopBarHeader icon={null}>{t('assets_map')}</TabbedLayoutTopBarHeader>
          <TabbedLayoutTopBarVerticalSeperator />

          <FilterBar showAsPopover onSearch={onSearch} buttonSize={'small'} setMinHeight={false} initialFilterValues={filters.current}>
            <div className="flex flex-col gap-y-2">
              <div className="w-72">
                <AssetTypesFilter label={'Asset Types'} />
              </div>
              <div className="w-72">
                <LabelValuesFilter filterFieldName={FilterFieldName.labelValueIds} />
              </div>
            </div>
            <FilterBarSearchButton />
          </FilterBar>
        </TabbedLayoutTopBar>
      }
    >
      <TabbedPageLayoutBody>
        <div className="absolute inset-0">
          <ClusterMap markers={locationMarkers} />
        </div>
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
